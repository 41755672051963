export function getNamecardData(num) {
    let result = {
        name: "Josh Choo",
        position: "Managing Director",
        contact: {
            label: "018-870 3905",
            phone: "60188703905",
            whatsapp: "60188703905",
            email: "sales@netzachtechsolution.com",
        },
        bio: "Netzach Tech Solution is a IT solutions business services company that providing services: IT solution, Server setup configuration, Networking Wifi Solution, Web Development, App Development, Custom Software Development, Backup Solution, Organization Email, Maintenance and Support.",
        imageUrl: "assets/img/namecard/random.png",
    }
    if (num == 1) {
        result = {
            name: "Sia Jia Xuan",
            position: "Software Engineer",
            contact: {
                label: "012-644 0810",
                phone: "60126440810",
                whatsapp: "60126440810",
                email: "tech.netzachtechsolution.com",
            },
            bio: "Netzach Tech Solution is a IT solutions business services company that providing services: IT solution, Server setup configuration, Networking Wifi Solution, Web Development, App Development, Custom Software Development, Backup Solution, Organization Email, Maintenance and Support.",
            imageUrl: "assets/img/namecard/random.png",
        }
    }
    else if (num == 2) {
        result = {
            name: "Eng Jing Hui",
            position: "Software Engineer",
            contact: {
                label: "016-756 2383",
                phone: "60167562383",
                whatsapp: "60167562383",
                email: "tech.netzachtechsolution.com",
            },
            bio: "Netzach Tech Solution is a IT solutions business services company that providing services: IT solution, Server setup configuration, Networking Wifi Solution, Web Development, App Development, Custom Software Development, Backup Solution, Organization Email, Maintenance and Support.",
            imageUrl: "assets/img/namecard/random.png",
        }
    }
    else if (num == 3) {
        result = {
            name: "Josh Choo",
            position: "Managing Director",
            contact: {
                label: "018-870 3905",
                phone: "60188703905",
                whatsapp: "60188703905",
                email: "sales@netzachtechsolution.com",
            },
            bio: "Netzach Tech Solution is a IT solutions business services company that providing services: IT solution, Server setup configuration, Networking Wifi Solution, Web Development, App Development, Custom Software Development, Backup Solution, Organization Email, Maintenance and Support.",
            imageUrl: "assets/img/namecard/random.png",
        }
    }
    else if (num == 4) {
        result = {
            name: "Chang Wei Jin",
            position: "Sales Director",
            contact: {
                label: "01161783518",
                phone: "601161783518",
                whatsapp: "6587689921",
                email: "sales@netzachtechsolution.com",
            },
            bio: "Netzach Tech Solution is a IT solutions business services company that providing services: IT solution, Server setup configuration, Networking Wifi Solution, Web Development, App Development, Custom Software Development, Backup Solution, Organization Email, Maintenance and Support.",
            imageUrl: "assets/img/namecard/random.png",
        }
    }
    return result;
}