import React, { useRef } from "react";
import { useSpring, animated, useScroll, useInView } from '@react-spring/web'
function OurData() {
    const [ref, inView] = useInView()
    const myDuration = 3000;
    const d = new Date();
    const year = d.getFullYear();
    
    const animatedValue1 = useSpring({
        from: { value: 0 },
        to: inView ? { value: year-2022 } : 0,
        config: { duration: myDuration },
    })
    const animatedValue2 = useSpring({
        from: { value: 0 },
        to: inView ? { value: 50 } : 0,
        config: { duration: myDuration },
    })
    const animatedValue3 = useSpring({
        from: { value: 0 },
        to: inView ? { value: 50 } : 0,
        config: { duration: myDuration },
    })
    const animatedValue4 = useSpring({
        from: { value: 0 },
        to: inView ? { value: 100 } : 0,
        config: { duration: myDuration },
    })



    return (
        <section ref={ref} className="py-3 py-xl-5 section-business-rating mb-5">
            <div className="container">
                <div className="row">
                    {/* <div className="col-md-3">
                        <animated.h3>{inView ? animatedValue1.value.to(x => x.toFixed(0)) : 0}</animated.h3>
                        <h6>Years Helping Business</h6>
                    </div> */}
                    <div className="col-md-4">
                        <animated.h3>{inView ? animatedValue2.value.to(x => x.toFixed(0)+"+") : 0}</animated.h3>
                        <h6>Project Templates</h6>
                    </div>
                    <div className="col-md-4">
                        <animated.h3>{inView ? animatedValue3.value.to(x => x.toFixed(0)+"+") : 0}</animated.h3>
                        <h6>Complete Projects</h6>
                    </div>
                    <div className="col-md-4">
                        <animated.h3>{inView ? animatedValue4.value.to(x => x.toFixed(0)+"+") : 0}</animated.h3>
                        <h6>5 Star Rating</h6>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default OurData;