import React from "react";
import JobDetails from "./JobDetails";

function JobOpening() {
    return (
        <section className="my-5">
            <div className="container">
                <div className="row mb-2">
                    <div className="col-md-8 col-xl-6 text-center mx-auto">
                        <h2 className="display-6 fw-bold mb-5"><span className="pb-3 underline">We Are Hiring...<br /></span></h2>
                        {/* <p className="text-muted mb-5">Curae hendrerit donec commodo hendrerit egestas tempus, turpis facilisis nostra nunc. Vestibulum dui eget ultrices.</p> */}
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="mx-auto">
                        <div className="accordion text-muted" role="tablist" id="accordion-1">
                            <JobDetails 
                                item = "1"
                                JobTitle = "Junior Software Engineer"
                                JobDescription = "As a Junior Software Engineer, you will be an integral part of our software development team, contributing to the design, development, and implementation of software solutions."
                                JobScope = {[
                                    "Software Development: Collaborate with senior engineers and stakeholders to understand project requirements and translate them into well‐designed, efficient, and maintainable code. Write clean, modular, and scalable code following best practices and coding standards.",
                                    "Testing and Debugging: Conduct unit testing and assist in the debugging process to identify and resolve software defects. Participate in code reviews to ensure code quality and adherence to established guidelines.",
                                    "Documentation: Create and maintain technical documentation such as specifications, system architecture, and user manuals. Document code changes and update existing documentation as necessary",
                                    "Collaboration: Work closely with cross‐functional teams, including product managers, designers, and quality assurance engineers, to ensure smooth integration and delivery of software solutions. Actively participate in team meetings, brainstorming sessions, and knowledge sharing activities.",
                                    "Continuous Learning: Stay updated with emerging technologies, programming languages, and industry trends. Continuously enhance your technical skills and knowledge through self‐study, online courses, and relevant certifications."
                                ]}
                                JobRequirements = {[
                                    "Education: Bachelor's degree in Computer Science, Software Engineering, or a related field. Equivalent practical experience may be considered.",
                                    "Programming Skills: Proficiency in at least one programming language (e.g., Java, Python, C#, C++, JavaScript). Familiarity with software development frameworks and libraries is a plus.",
                                    "Problem‐Solving Abilities: Strong analytical and problem‐solving skills to understand complex requirements, identify issues, and propose effective solutions.",
                                    "Teamwork: Ability to work collaboratively in a team‐oriented environment. Excellent communication skills to effectively interact with team members, stakeholders, and clients.",
                                    "Attention to Detail: Meticulousness in writing clean, well‐documented code. Strong focus on quality assurance and ensuring the reliability of software solutions.",
                                    "Adaptability: Willingness to learn and adapt to new technologies, programming languages, and frameworks. Ability to quickly grasp new concepts and apply them in practical scenarios.",
                                    "Self‐Motivation: Proactive and self‐driven attitude with the ability to work independently and manage time effectively. Willingness to take ownership of assigned tasks and deliver them within deadlines.",
                                    "Continuous Improvement: Strong desire to continuously learn and improve technical skills. Participation in coding competitions, open‐source projects, or personal software development projects is a plus"
                                ]}
                            />
                            <JobDetails 
                                item = "2"
                                JobTitle = "Sales Executive"
                                JobDescription = "As an IT Sales Executive, you will be responsible for driving sales growth and generating revenue by selling IT products, solutions, and services to prospective clients."
                                JobScope = {[
                                    "Sales Target Achievement: Meet or exceed sales targets by identifying and pursuing new business opportunities. Develop and maintain a strong sales pipeline, conduct sales presentations, and negotiate contracts to secure new customers.",
                                    "Customer Relationship Management: Build and maintain strong relationships with existing and potential clients. Understand their IT needs, provide tailored solutions, and address any inquiries or concerns promptly. Ensure customer satisfaction and act as a trusted advisor.",
                                    "Product Knowledge: Develop a deep understanding of the IT products, solutions, and services offered by the company. Stay updated with industry trends, market competition, and emerging technologies to effectively position and differentiate the company's offerings",
                                    "Market Research and Prospecting: Conduct market research to identify potential customers nd target industries. Generate leads through various channels, including cold calling, networking events, referrals, and online research. Qualify leads and prioritize opportunities based on potential value and fit",
                                    "Sales Presentations and Proposals: Prepare and deliver compelling sales presentations to showcase the value proposition of IT products, solutions, and services. Customize proposals and quotes based on customer requirements and demonstrate how the company's offerings meet their specific needs.",
                                    "Sales Forecasting and Reporting: Accurately forecast sales opportunities and provide regular reports on sales activities, pipelines, and progress against targets. Utilize CRM systems or other sales tools to track customer interactions, document sales activities, and maintain up‐to‐date records.",
                                    "Collaboration and Coordination: Work closely with cross‐functional teams, including product managers, technical experts, and customer support, to ensure smooth delivery and implementation of IT solutions. Collaborate with internal stakeholders to develop pricing strategies, promotions, and marketing campaigns to drive sales growth."
                                ]}
                                JobRequirements = {[
                                    "Education: Bachelor's degree in Business Administration, Marketing, Information Technology, or a related field. Relevant certifications in sales or IT will be advantageous.",
                                    "Sales Experience: Proven track record of success in IT sales or a related field. Experience in selling IT products, software solutions, or IT services is highly desirable.",
                                    "Technical Knowledge: Familiarity with IT products, services, and solutions, such as hardware, software, networking, cloud services, cybersecurity, or IT consulting. Ability to understand and effectively communicate technical concepts to non‐technical stakeholders.",
                                    "Communication Skills: Excellent verbal and written communication skills. Ability to articulate complex ideas clearly and concisely. Active listening skills to understand customer needs and provide appropriate solutions.",
                                    "Sales Skills: Strong negotiation, persuasion, and closing skills. Ability to build and maintain long‐term customer relationships. Experience with consultative selling approaches and solution‐based selling is advantageous.",
                                    "Results Orientation: Proven ability to meet or exceed sales targets and drive revenue growth. Self‐motivated with a competitive mindset and a focus on achieving tangible results. Ability to thrive in a target‐driven and fast‐paced sales environment.",
                                    "Market Knowledge: Up‐to‐date knowledge of the IT industry, market trends, and competition. Ability to identify emerging opportunities and potential challenges in the IT sales landscape.",
                                    "Relationship Building: Strong interpersonal skills and the ability to establish rapport with clients. Customer‐centric approach with a dedication to providing excellent service and building long‐term partnerships."
                                ]}
                            />
                            <JobDetails 
                                item = "3"
                                JobTitle = "Graphic Designer"
                                JobDescription = "As a Webpage Graphic Designer, you will play a crucial role in creating visually appealing and user‐friendly webpages."
                                JobScope = {[
                                    "Visual Design: Create visually engaging designs for webpages, ensuring a cohesive and appealing visual aesthetic. Develop layout concepts, typography, and color schemes that align with the brand's identity and objectives.",
                                    "User Experience (UX): Collaborate with UX/UI designers and developers to create webpages that provide an intuitive and seamless user experience. Incorporate user‐centered design principles to optimize usability, accessibility, and overall user satisfaction.",
                                    "Graphic Creation and Editing: Design and manipulate graphics, icons, images, and other visual elements for webpages. Ensure the graphics are optimized for web usage and aligned with the webpage's purpose and content.",
                                    "Responsive Design: Design webpages that are responsive and compatible with multiple devices and screen sizes. Ensure the designs adapt well to different resolutions and maintain a consistent user experience across various platforms.",
                                    "Collaboration and Feedback: Collaborate with stakeholders, including clients, project managers, and web developers, to understand project requirements and incorporate feedback throughout the design process. Iterate and refine designs based on feedback and usability testing results.",
                                    "Design Tools and Software: Utilize design tools and software, such as Adobe Photoshop, Adobe Illustrator, Sketch, or Figma, to create and refine webpage designs. Stay updated with the latest design trends and tools to enhance design capabilities.",
                                    "Branding and Style Guidelines: Ensure adherence to brand guidelines and maintain consistency with the overall brand identity in webpage designs. Maintain style guides and design libraries for efficient and consistent design implementation."
                                ]}
                                JobRequirements = {[
                                    "Education: Bachelor's degree in Graphic Design, Visual Communication, Web Design, or a related field. Equivalent practical experience may be considered.",
                                    "Graphic Design Skills: Proficiency in graphic design software such as Adobe Photoshop, Illustrator, or Sketch. Strong understanding of design principles, typography, color theory, and layout composition.",
                                    "Web Design and Development: Familiarity with web design principles, responsive design techniques, and web development languages (e.g., HTML, CSS). Ability to collaborate effectively with web developers to ensure seamless integration of designs.",
                                    "User‐Centered Design: Knowledge of user‐centered design principles and best practices. Ability to empathize with users, understand their needs, and create visually appealing designs that enhance the user experience.",
                                    "Portfolio: A strong portfolio demonstrating expertise in webpage graphic design. The portfolio should showcase a range of web design projects, highlighting creativity, attention to detail, and the ability to create designs aligned with brand guidelines.",
                                    "Communication and Collaboration: Excellent communication skills to effectively collaborate with cross‐functional teams, understand project requirements, and incorporate feedback. Ability to present and articulate design concepts and decisions.",
                                    "Time Management: Strong organizational skills and the ability to manage multiple projects simultaneously. Demonstrated ability to work within deadlines and deliver high‐quality designs on time.",
                                    "Attention to Detail: Meticulousness in design execution and attention to detail. Strong focus on quality assurance to ensure accurate and error‐free designs."
                                ]}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default JobOpening;