import React from "react";
import "../css/Homepage.css";
import HomeTitleWithBackground from "../components/HomeTitleWithBackground";
import Services from "../components/Services";
import HowWeHelp from "../components/HowWeHelp";
import WhyChooseUs from "../components/WhyChooseUs";
import OurData from "../components/OurData";
import SubscribeEmail from "../components/SubscribeEmail";
import NeedOurHelp from "../components/NeedOurHelp";
import { useSpring, animated, useScroll, useInView, Transition } from '@react-spring/web'
function Homepage() {
    const [refService, inViewService] = useInView()
    const animatedDisplay1 = useSpring({
        opacity: inViewService ? 1 : 0,
        config: { duration: 1000 }
    })
    const [refHowWeHelp, inViewHowWeHelp] = useInView()
    const animatedDisplay2 = useSpring({
        opacity: inViewHowWeHelp ? 1 : 0,
        config: { duration: 2000 }
    })
    const [refWhyChooseUs, inViewWhyChooseUs] = useInView()
    const animatedDisplay3 = useSpring({
        opacity: inViewWhyChooseUs ? 1 : 0,
        config: { duration: 2000 }
    })
    const [refSubscribeEmail, inViewSubscribeEmail] = useInView()
    const animatedDisplay4 = useSpring({
        opacity: inViewSubscribeEmail ? 1 : 0,
        config: { duration: 2000 }
    })
    const [refNeedOurHelp, inViewNeedOurHelp] = useInView()
    const animatedDisplay5 = useSpring({
        opacity: inViewNeedOurHelp ? 1 : 0,
        config: { duration: 2000 }
    })
    return (
        <>
        
        <HomeTitleWithBackground 
        title = "IT Solutions &amp; Business Services Company"
        description = "The software development industry is a rapidly growing sector with increasing demand for custom software solutions across industries. With the growth of digital transformation, businesses require software that is tailored to their specific needs to remain competitive in the market."
        buttonSecondary = "Our Service"
        buttonSecondaryLinkTo = "/service"
        buttonPrimary = "Contact Us"
        buttonPrimaryLinkTo = "/contactUs"
        />

        <animated.section ref={refService} style={animatedDisplay1}>
        <Services />
        </animated.section>

        <animated.section ref={refHowWeHelp} style={animatedDisplay2}>
        <HowWeHelp />
        </animated.section>

        <animated.section ref={refWhyChooseUs} style={animatedDisplay3}>
        <WhyChooseUs />
        </animated.section>

        <OurData />

        {/* <animated.section ref={refSubscribeEmail} style={animatedDisplay4}>
        <SubscribeEmail />
        </animated.section> */}

        <animated.section ref={refNeedOurHelp} style={animatedDisplay5}>
        <NeedOurHelp />
        </animated.section>
        </>
    )
}
export default Homepage;