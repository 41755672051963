import './App.css';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Homepage from './pages/Homepage';
import Header from './nav/Header';
import Footer from './nav/Footer';
import Service from './pages/Service';
import AboutUs from './pages/AboutUs';
import Career from './pages/Career';
import ContactUs from './pages/ContactUs';
import Faq from './pages/Faq';
import ScrollToTop from './helpers/ScrollToTop';
import ChatIcon from './components/ChatIcon';
import Namecard from './components/Namecard';

function App() {
  return (
    <Router>
      <RouteLink />
    </Router>
  );
}

function RouteLink() {
  let location = useLocation();
  let isNameCard = location.pathname === "/namecard";

  return (
    <div>
      {!isNameCard && <Header />}
      <ScrollToTop>
      {!isNameCard && <ChatIcon />}
        <Routes>
          <Route exact path='/' element={ <Homepage /> } />
          <Route exact path='/service' element={ <Service /> } />
          <Route exact path='/aboutUs' element={ <AboutUs /> } />
          <Route exact path='/careers' element={ <Career /> } />
          <Route exact path='/contactUs' element={ <ContactUs /> } />
          {/* <Route exact path='/faq' element={ <Faq /> } /> */}
          <Route exact path='/namecard' element={ <Namecard /> } />
        </Routes>
      </ScrollToTop>
      {!isNameCard && <Footer />}
    </div>
  );
}

export default App;
