import React from "react";
import { Link } from "react-router-dom";

function NeedOurHelp() {
    return (
        <div className="py-4 py-xl-5">
            <div className="container">
                <div className="text-white bg-primary border rounded border-0 border-primary d-flex flex-column justify-content-between flex-lg-row p-4 p-md-5">
                    <div className="pb-2 pb-lg-1">
                        <h2 className="fw-bold text-warning mb-2">Need our help?</h2>
                        <p className="mb-0">Contact us today to learn more about how our IT solutions can benefit your business.</p>
                    </div>
                    <div className="my-2">
                        <Link to="/contactUs" className="btn btn-light fs-5 py-2 px-4">
                        Contact us
                        </Link>
                        {/* <a className="btn btn-light fs-5 py-2 px-4" role="button" href="#">Contact us</a> */}
                        </div>
                </div>
            </div>
        </div>
    )
}
export default NeedOurHelp;