import React from "react";

function HowWeHelp() {
    return (
            <div className="container py-4 py-xl-5">
                <div className="row">
                    <div className="col-md-6">
                        <h3 className="display-6 fw-bold pb-md-4">Achieve digital transformation for your retail business services</h3>
                    </div>
                    <div className="col-md-6 pt-4">
                        <p className="text-muted mb-4">Revolutionize your retail business with our expert digital transformation services, tailored to modernize your operations and drive your success in the digital age.</p>
                    </div>
                </div>
                <div className="row gy-4 gy-md-0">
                    <div className="col-md-6 d-flex d-sm-flex d-md-flex justify-content-center align-items-center justify-content-md-start align-items-md-center justify-content-xl-center">
                        <div>
                            <div className="row gy-2 row-cols-1 row-cols-sm-2">
                                <div className="col text-center text-md-start">
                                    <div className="d-flex justify-content-center align-items-center justify-content-md-start"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round" className="icon icon-tabler icon-tabler-sun fs-3 text-primary bg-warning">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                            <circle cx="12" cy="12" r="4"></circle>
                                            <path d="M3 12h1m8 -9v1m8 8h1m-9 8v1m-6.4 -15.4l.7 .7m12.1 -.7l-.7 .7m0 11.4l.7 .7m-12.1 -.7l-.7 .7"></path>
                                        </svg>
                                        <h6 className="fw-bold mb-0 ms-2">Enhanced customer experience</h6>
                                    </div>
                                    <p className="text-muted my-3">Personalized, seamless, and engaging experiences across all touchpoints.</p>
                                </div>
                                <div className="col text-center text-md-start">
                                    <div className="d-flex justify-content-center align-items-center justify-content-md-start"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round" className="icon icon-tabler icon-tabler-sun fs-3 text-primary bg-warning">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                            <circle cx="12" cy="12" r="4"></circle>
                                            <path d="M3 12h1m8 -9v1m8 8h1m-9 8v1m-6.4 -15.4l.7 .7m12.1 -.7l-.7 .7m0 11.4l.7 .7m-12.1 -.7l-.7 .7"></path>
                                        </svg>
                                        <h6 className="fw-bold mb-0 ms-2">Enhanced employee productivity</h6>
                                    </div>
                                    <p className="text-muted my-3">Boost productivity and job satisfaction with our digital solutions.</p>
                                </div>
                                <div className="col text-center text-md-start">
                                    <div className="d-flex justify-content-center align-items-center justify-content-md-start"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round" className="icon icon-tabler icon-tabler-sun fs-3 text-primary bg-warning">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                            <circle cx="12" cy="12" r="4"></circle>
                                            <path d="M3 12h1m8 -9v1m8 8h1m-9 8v1m-6.4 -15.4l.7 .7m12.1 -.7l-.7 .7m0 11.4l.7 .7m-12.1 -.7l-.7 .7"></path>
                                        </svg>
                                        <h6 className="fw-bold mb-0 ms-2">Improved operational efficiency</h6>
                                    </div>
                                    <p className="text-muted my-3">Optimize retail operations with our advanced digital solutions.</p>
                                </div>
                                <div className="col text-center text-md-start">
                                    <div className="d-flex justify-content-center align-items-center justify-content-md-start"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round" className="icon icon-tabler icon-tabler-sun fs-3 text-primary bg-warning">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                            <circle cx="12" cy="12" r="4"></circle>
                                            <path d="M3 12h1m8 -9v1m8 8h1m-9 8v1m-6.4 -15.4l.7 .7m12.1 -.7l-.7 .7m0 11.4l.7 .7m-12.1 -.7l-.7 .7"></path>
                                        </svg>
                                        <h6 className="fw-bold mb-0 ms-2">Improved data security and compliance</h6>
                                    </div>
                                    <p className="text-muted my-3">Secure your business data and protect against cyber threats.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 order-first order-md-last floating-animation">
                        <div><img className="rounded img-fluid w-100 fit-cover min-h-300" src="assets/img/illustrations/teamwork.svg" alt="business img"/></div>
                    </div>
                </div>
            </div>
    )
}
export default HowWeHelp;