import React from "react";
import "../css/Homepage.css";

function Services() {
    return (
            <div className="container py-4 py-xl-5 mt-5 our-services">
                <h1 className="text-center">What We Provide</h1>
                <div className="row gy-4 row-cols-1 row-cols-md-2 row-cols-lg-3 mb-3">
                    <div className="col">
                        <div className="card border-light border-1 d-flex justify-content-center p-4">
                            <div className="card-body">
                                <div className="bs-icon-lg bs-icon-rounded bs-icon-secondary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-4 bs-icon" data-bss-hover-animate="tada"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" className="bi bi-lightbulb">
                                        <path d="M2 6a6 6 0 1 1 10.174 4.31c-.203.196-.359.4-.453.619l-.762 1.769A.5.5 0 0 1 10.5 13a.5.5 0 0 1 0 1 .5.5 0 0 1 0 1l-.224.447a1 1 0 0 1-.894.553H6.618a1 1 0 0 1-.894-.553L5.5 15a.5.5 0 0 1 0-1 .5.5 0 0 1 0-1 .5.5 0 0 1-.46-.302l-.761-1.77a1.964 1.964 0 0 0-.453-.618A5.984 5.984 0 0 1 2 6zm6-5a5 5 0 0 0-3.479 8.592c.263.254.514.564.676.941L5.83 12h4.342l.632-1.467c.162-.377.413-.687.676-.941A5 5 0 0 0 8 1z"></path>
                                    </svg></div>
                                <div>
                                    <h4 className="fw-bold">IT Solution</h4>
                                    <p className="text-muted">Our IT Solution services provide tailored solutions to optimize your business operations and increase efficiency using the latest technologies in software development, network management, cybersecurity, and cloud solutions.</p>
                                    {/* <button className="btn btn-sm px-0" type="button">Learn More&nbsp;<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" className="bi bi-arrow-right">
                                            <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"></path>
                                        </svg><br /></button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card border-light border-1 d-flex justify-content-center p-4">
                            <div className="card-body">
                                <div className="bs-icon-lg bs-icon-rounded bs-icon-secondary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-4 bs-icon" data-bss-hover-animate="tada">
                                <i className="fas fa-server"></i></div>
                                <div>
                                    <h4 className="fw-bold">Server Setup & Configuration</h4>
                                    <p className="text-muted"><span>Maximize your business potential with our efficient server setup services, designed to ensure smooth and secure server deployment, management, and maintenance for your critical applications and data.</span>
                                    <br />
                                    </p>
                                    {/* <button className="btn btn-sm px-0" type="button">Learn More&nbsp;<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" className="bi bi-arrow-right">
                                            <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"></path>
                                        </svg><br /></button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card border-light border-1 d-flex justify-content-center p-4">
                            <div className="card-body">
                                <div className="bs-icon-lg bs-icon-rounded bs-icon-secondary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-4 bs-icon" data-bss-hover-animate="tada"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round" className="icon icon-tabler icon-tabler-rss">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <circle cx="5" cy="19" r="1"></circle>
                                        <path d="M4 4a16 16 0 0 1 16 16"></path>
                                        <path d="M4 11a9 9 0 0 1 9 9"></path>
                                    </svg></div>
                                <div>
                                    <h4 className="fw-bold">Networking and Wifi Solution</h4>
                                    <p className="text-muted">Stay connected with our reliable network and Wifi solutions, engineered to deliver seamless connectivity and uninterrupted performance for your business operations, anytime and anywhere.</p>
                                    {/* <button className="btn btn-sm px-0" type="button">Learn More&nbsp;<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" className="bi bi-arrow-right">
                                            <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"></path>
                                        </svg><br /></button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row gy-4 row-cols-1 row-cols-md-2 row-cols-lg-3 mb-3">
                    <div className="col">
                        <div className="card border-light border-1 d-flex justify-content-center p-4">
                            <div className="card-body">
                                <div className="bs-icon-lg bs-icon-rounded bs-icon-secondary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-4 bs-icon" data-bss-hover-animate="tada"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" className="bi bi-window-fullscreen">
                                        <path fill-rule="evenodd" d="M.5 1a.5.5 0 0 0-.5.5v13a.5.5 0 0 0 .5.5h15a.5.5 0 0 0 .5-.5v-13a.5.5 0 0 0-.5-.5H.5ZM1 5V2h14v3H1Zm0 1h14v8H1V6Zm2-2.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Zm1.5 0a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Zm1 .5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Z"></path>
                                    </svg></div>
                                <div>
                                    <h4 className="fw-bold">Web Development</h4>
                                    <p className="text-muted"><span>Unlock the full potential of your online presence with our professional web development services, designed to create visually stunning and user-friendly websites that leave a lasting impression on your audience.</span>
                                    <br />
                                    </p>
                                    {/* <button className="btn btn-sm px-0" type="button">Learn More&nbsp;<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" className="bi bi-arrow-right">
                                            <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"></path>
                                        </svg><br /></button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card border-light border-1 d-flex justify-content-center p-4">
                            <div className="card-body">
                                <div className="bs-icon-lg bs-icon-rounded bs-icon-secondary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-4 bs-icon" data-bss-hover-animate="tada"><svg xmlns="http://www.w3.org/2000/svg" viewBox="-64 0 512 512" width="1em" height="1em" fill="currentColor">
                                        <path d="M320 0H64C37.5 0 16 21.5 16 48v416C16 490.5 37.5 512 64 512h256c26.5 0 48-21.5 48-48v-416C368 21.5 346.5 0 320 0zM240 447.1C240 456.8 232.8 464 224 464H159.1C151.2 464 144 456.8 144 448S151.2 432 160 432h64C232.8 432 240 439.2 240 447.1zM304 384h-224V64h224V384z"></path>
                                    </svg></div>
                                <div>
                                    <h4 className="fw-bold">App Development</h4>
                                    <p className="text-muted">Create innovative and intuitive mobile apps that engage your customers and drive business growth with our expert app development services, utilizing the latest technologies and methodologies to bring your vision to life.</p>
                                    {/* <button className="btn btn-sm px-0" type="button">Learn More&nbsp;<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" className="bi bi-arrow-right">
                                            <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"></path>
                                        </svg><br /></button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card border-light border-1 d-flex justify-content-center p-4">
                            <div className="card-body">
                                <div className="bs-icon-lg bs-icon-rounded bs-icon-secondary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-4 bs-icon" data-bss-hover-animate="tada">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-32 0 512 512" width="1em" height="1em" fill="currentColor">
                                        <path d="M120.12 208.29c-3.88-2.9-7.77-4.35-11.65-4.35H91.03v104.47h17.45c3.88 0 7.77-1.45 11.65-4.35 3.88-2.9 5.82-7.25 5.82-13.06v-69.65c-.01-5.8-1.96-10.16-5.83-13.06zM404.1 32H43.9C19.7 32 .06 51.59 0 75.8v360.4C.06 460.41 19.7 480 43.9 480h360.2c24.21 0 43.84-19.59 43.9-43.8V75.8c-.06-24.21-19.7-43.8-43.9-43.8zM154.2 291.19c0 18.81-11.61 47.31-48.36 47.25h-46.4V172.98h47.38c35.44 0 47.36 28.46 47.37 47.28l.01 70.93zm100.68-88.66H201.6v38.42h32.57v29.57H201.6v38.41h53.29v29.57h-62.18c-11.16.29-20.44-8.53-20.72-19.69V193.7c-.27-11.15 8.56-20.41 19.71-20.69h63.19l-.01 29.52zm103.64 115.29c-13.2 30.75-36.85 24.63-47.44 0l-38.53-144.8h32.57l29.71 113.72 29.57-113.72h32.58l-38.46 144.8z"></path>
                                    </svg></div>
                                <div>
                                    <h4 className="fw-bold">Custom software development</h4>
                                    <p className="text-muted">
                                        <span>Empower your business with our custom software development services, tailored to your unique requirements and goals, and designed to deliver scalable, efficient, and cost-effective solutions that drive your success.</span></p>
                                        {/* <button className="btn btn-sm px-0" type="button">Learn More&nbsp;<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" className="bi bi-arrow-right">
                                            <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"></path>
                                        </svg><br /></button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row gy-4 row-cols-1 row-cols-md-2 row-cols-lg-3 mb-3">
                    <div className="col">
                        <div className="card border-light border-1 d-flex justify-content-center p-4">
                            <div className="card-body">
                                <div className="bs-icon-lg bs-icon-rounded bs-icon-secondary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-4 bs-icon" data-bss-hover-animate="tada"><i class="fas fa-undo"></i></div>
                                <div>
                                    <h4 className="fw-bold"><span>Backup Solution</span></h4>
                                    <p className="text-muted"><span>Safeguard your business data with our reliable backup solution services, ensuring secure and automated backups of your critical data and applications, minimizing downtime and mitigating risks of data loss or corruption.</span></p>
                                    {/* <button className="btn btn-sm px-0" type="button">Learn More&nbsp;<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" className="bi bi-arrow-right">
                                            <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"></path>
                                        </svg><br /></button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card border-light border-1 d-flex justify-content-center p-4">
                            <div className="card-body">
                                <div className="bs-icon-lg bs-icon-rounded bs-icon-secondary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-4 bs-icon" data-bss-hover-animate="tada"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round" className="icon icon-tabler icon-tabler-mail">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <rect x="3" y="5" width="18" height="14" rx="2"></rect>
                                        <polyline points="3 7 12 13 21 7"></polyline>
                                    </svg></div>
                                <div>
                                    <h4 className="fw-bold">Organization Email</h4>
                                    <p className="text-muted"><span>Communicate professionally and securely with our organization email services, featuring reliable email hosting, encryption, and spam protection, ensuring seamless communication and collaboration for your business.</span></p>
                                    {/* <button className="btn btn-sm px-0" type="button">Learn More&nbsp;<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" className="bi bi-arrow-right">
                                            <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"></path>
                                        </svg><br /></button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card border-light border-1 d-flex justify-content-center p-4">
                            <div className="card-body">
                            <div className="bs-icon-lg bs-icon-rounded bs-icon-secondary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-4 bs-icon" data-bss-hover-animate="tada"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round" className="icon icon-tabler icon-tabler-tool">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <path d="M7 10h3v-3l-3.5 -3.5a6 6 0 0 1 8 8l6 6a2 2 0 0 1 -3 3l-6-6a6 6 0 0 1 -8 -8l3.5 3.5"></path>
                                    </svg></div>
                                <div>
                                    <h4 className="fw-bold"><span>Maintenance and Support</span></h4>
                                    <p className="text-muted"><span>Experience uninterrupted operations and optimized performance with our reliable maintenance and support services for hardware and software, providing comprehensive solutions to mitigate potential issues and ensure maximum uptime for your business.</span></p>
                                    {/* <button className="btn btn-sm px-0" type="button">Learn More&nbsp;<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" className="bi bi-arrow-right">
                                            <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"></path>
                                        </svg><br /></button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}
export default Services;