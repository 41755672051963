import React from "react";
import "../css/Namecard.css";
import { Link } from "react-router-dom";
import { getNamecardData } from "../helpers/storageHelper";
import QRCode from 'qrcode';
function Namecard(props) {
    let params = new URLSearchParams(window.location.search);
    let id = params.get('id');
    const namecardData = getNamecardData(id);
    const facebook_url = process.env.REACT_APP_FACEBOOK_URL;
    const instagram_url = process.env.REACT_APP_INSTAGRAM_URL;
    const xiaohongshu_url = process.env.REACT_APP_XIAOHONGSHU_URL;
    const company_full_name = process.env.REACT_APP_COMPANY_FULL_NAME;
    const company_website = process.env.REACT_APP_COMPANY_WEBSITE;

    async function generateQRCode() {
        const qrCodeElement = document.getElementById('qrCodeElement');
        // clear qrCodeElement
        qrCodeElement.innerHTML = "";
        const qrCodeUrl = await QRCode.toDataURL(window.location.href);
        const img = document.createElement('img');
        img.src = qrCodeUrl;
        qrCodeElement.appendChild(img);
    }

    function generateVCard() {
        // this indent cannot be modified, it is the format for vcard
        return `BEGIN:VCARD
VERSION:3.0
N:${namecardData.name}
FN:${namecardData.name}
ORG:${company_full_name}
TITLE:${namecardData.position}
TEL;TYPE=WORK,VOICE:${namecardData.contact.whatsapp}
EMAIL;TYPE=PREF,INTERNET:${namecardData.contact.email}
item0.URL:${facebook_url}
item0.X-ABLabel:Facebook
item1.URL:${instagram_url}
item1.X-ABLabel:Instagram
item2.URL:${xiaohongshu_url}
item2.X-ABLabel:Xiao Hong Shu
item3.URL:${company_website}
item3.X-ABLabel:Official Website
END:VCARD`;
    }

    function downloadVCard() {
        var blob = new Blob([generateVCard()], { type: 'text/vcard;charset=utf-8;' });
        var url = URL.createObjectURL(blob);
        var downloadLink = document.createElement("a");
        downloadLink.href = url;
        downloadLink.download = "contact.vcf";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }

    return (
        <div className="container">
            <section className="d-flex justify-content-between align-items-center">
                <Link to="/">
                <img alt="logo" className="namecard-logo" src="assets/img/Netzach_logo_small.png" />
                </Link>
                    <span className="d-flex namecard-navlink-holder">
                        <a onClick={()=>downloadVCard()} href="#">
                            <i className="fas fa-download"></i>
                            Save Contact
                        </a>
                        <a onClick={()=>generateQRCode()} href="#" data-bs-toggle="modal" data-bs-target="#qrCodeModal">
                            <i className="fas fa-qrcode"></i>
                            QR Code
                        </a>
                    </span>
            </section>
            <section>
                <div className="row">
                    <div className="col text-center">
                        <img className="profile-img" src={namecardData.imageUrl} alt="profile-img" />
                        <h1>{namecardData.name}</h1>
                        <h5>{namecardData.position} | {company_full_name}</h5>
                        <h5 className="namecard-bio">
                            {namecardData.bio}
                        </h5>
                    </div>
                    <div className="col profile-details">
                        <h5>PHONE</h5>
                        <h5 className="mb-3">
                            <a href={`tel:${namecardData.contact.phone}`}>
                                <i className="fas fa-phone-alt"></i>
                                <span>{namecardData.contact.label}</span>
                                <br />
                            </a>
                        </h5>
                        <h5>WHATSAPP</h5>
                        <h5 className="mb-3">
                            <a href={`https://wa.me/${namecardData.contact.whatsapp}`}>
                                <i className="fab fa-whatsapp"></i>
                                <span>{namecardData.contact.label}</span>
                                <br />
                            </a>
                        </h5>
                        <h5>EMAIL</h5>
                        <h5 className="mb-3">
                            <a href={`mailto:${namecardData.contact.email}`}>
                                <i className="far fa-envelope"></i>
                                <span>{namecardData.contact.email}</span>
                                <br />
                            </a>
                        </h5>
                        <hr />
                        <h5>LINKS</h5>
                        <h5 className="mb-3">
                            <a href={facebook_url}>
                                <i className="fab fa-facebook"></i>
                                <span>{company_full_name}</span>
                            <br />
                            </a>
                        </h5>
                        <h5 className="mb-3">
                            <a href={instagram_url}>
                                <i className="fab fa-instagram"></i>
                                <span>Instagram</span>
                            <br />
                            </a>
                        </h5>
                        <h5 className="mb-3">
                            <a href={xiaohongshu_url}>
                                <i className="fas fa-book"></i>
                                <span>Xiao Hong Shu</span>
                            <br />
                            </a>
                        </h5>
                        <h5 className="mb-3">
                            <a href={company_website}>
                                <i className="fab fa-chrome"></i>
                                <span>Official Website</span>
                            <br />
                            </a>
                        </h5>
                    </div>
                </div>
            </section>

            <div className="modal" tabindex="-1" id="qrCodeModal">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">QR Code</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body text-center">
                        <div id="qrCodeElement"></div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Namecard;