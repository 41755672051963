import React from "react";
import HomeTitleWithBackground from "../components/HomeTitleWithBackground";
import Services from "../components/Services";

function Service() {
    return (
        <>
            {/* <HomeTitleWithBackground 
            title = "Our Services"
            description = "We are a team of experts dedicated to providing customized technology solutions to meet the unique needs of your business. Our services cover a wide range of IT-related areas, including custom software development, web development, mobile app development, maintenance and support, software testing and QA, network design and implementation, server installation and management, data backup and disaster recovery, managed services, and hardware and software procurement."
            // buttonSecondary = "Our Service"
            buttonPrimaryLinkTo = "/contactUs"
            buttonPrimary = "Contact Us"
            /> */}
            <div className="pt-5">
                <Services />
            </div>
        </>
    )
}
export default Service;