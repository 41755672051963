function JobDetails(props) {
    const contactNumber = process.env.REACT_APP_CONTACT_NO; 
    const applyNow = (jobTitle) => {
        const whatsappMessage = `Hi, I'm interested in position ${jobTitle}`;
        const whatsappLink = `https://wa.me/${contactNumber}?text=${whatsappMessage}`;
        window.location.href = whatsappLink;
    }
    return (
        <div className="accordion-item">
            <h2 className="accordion-header" role="tab">
                <button className={`accordion-button ${props.item != '1' ? "collapsed" : ""}`} type="button" data-bs-toggle="collapse" data-bs-target={`#accordion-1 .item-${props.item}`} aria-expanded="true" aria-controls={`accordion-1 .item-${props.item}`}>
                {props.JobTitle}                
                </button>
            </h2>
            <div className={`accordion-collapse collapse item-${props.item} ${props.item == '1' ? "show" : ""}`} role="tabpanel" data-bs-parent={`#accordion-1`}>
                <div className="accordion-body">
                    <h6>
                        {props?.JobDescription}
                    </h6>
                    {
                        props?.JobScope && props.JobScope.length > 0 ?
                        <>
                            <strong>Job Scope:</strong>
                            <ul>
                            {props.JobScope.map((item, index) => (
                                <li key={index}>{item}</li>
                            ))}
                            </ul>
                        </>
                        : ""
                    }
                    {
                        props?.JobRequirements && props.JobRequirements.length > 0 ?
                        <>
                            <strong>Job Requirements:</strong>
                            <ul>
                            {props.JobRequirements.map((item, index) => (
                                <li key={index}>{item}</li>
                            ))}
                            </ul>
                        </>
                        : ""
                    }
                    <div className="text-end">
                        <button className="btn-ApplyNow" onClick={()=>applyNow(props.JobTitle)}>Apply Now</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default JobDetails;