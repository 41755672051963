import React from "react";
import "../css/Homepage.css";
import { Link } from "react-router-dom";

function HomeTitleWithBackground(props) {
    return (
        <section className="background-container">
            {
                props?.pageFrom == "Careers" ?
                <img className="background-careers-img" src="assets/img/background-careers.jpg" alt="background-careers" />
                : ""
            }
            <div data-bss-parallax-bg="true" className={props?.pageFrom == "Careers" ? "home-title-with-image-careers" : "home-title-with-image"} >
                <div className="container h-100 mt-5">
                    <div className="row h-100">
                        <div className="col-md-5 font-background text-center text-md-start d-flex d-sm-flex d-md-flex justify-content-center align-items-center justify-content-md-start align-items-md-center justify-content-xl-center">
                            <div className="mw-100">
                                <h1 className="text-uppercase fw-bold">{props?.title}</h1>
                                <p className="my-3 font-description">
                                {props?.description}
                                </p>
                                {
                                    props?.buttonSecondary ?
                                    <Link to={props?.buttonSecondaryLinkTo} >
                                        <button className="btn btn-primary my-btn-secondary" data-bss-hover-animate="pulse" type="button">
                                            {props?.buttonSecondary}
                                        </button>
                                    </Link>
                                    : ""
                                }
                                {
                                    props?.buttonPrimary ?
                                    <Link to={props?.buttonPrimaryLinkTo}>
                                        <button className="btn btn-primary my-btn-primary" type="button">
                                            {props?.buttonPrimary}
                                        </button>
                                    </Link>
                                    : ""
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default HomeTitleWithBackground;