import React from "react";
import JobOpening from "../components/JobOpening";
import HomeTitleWithBackground from "../components/HomeTitleWithBackground";


function Faq() {
    return (
        <>
        <HomeTitleWithBackground 
        pageFrom = "Careers"
        />
        <JobOpening />
        </>
    )
}
export default Faq;