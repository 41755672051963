import React from "react";
import HomeTitleWithBackground from "../components/HomeTitleWithBackground";
import HowWeHelp from "../components/HowWeHelp";
import WhyChooseUs from "../components/WhyChooseUs";

function AboutUs() {
    return (
        <>
        <HomeTitleWithBackground 
        title = "About Us"
        description = "We are a team of experienced professionals who are passionate about technology and dedicated to providing customized solutions to meet the unique needs of our clients. With years of experience in the industry, we have built a reputation for delivering high-quality services and exceptional customer support. We strive to stay at the forefront of the ever-evolving technology landscape, leveraging the latest tools and technologies to help our clients achieve their business goals."
        buttonSecondary = "Our Service"
        buttonSecondaryLinkTo = "/service"
        buttonPrimary = "Contact Us"
        buttonPrimaryLinkTo = "/contactUs"
        />

        <HowWeHelp />

        <WhyChooseUs />
        </>
    )
}
export default AboutUs;