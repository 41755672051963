import React from "react";
import { Link } from "react-router-dom";

function Header() {
    return (
        <nav className="navbar navbar-light navbar-expand-md fixed-top navbar-shrink" id="mainNav">
            <div className="container">
                <a className="navbar-brand d-flex align-items-center" href="/">
                <span><img src="assets/img/Netzach_logo_small_HD.png" alt="logo" className="nav-logo" /></span>
                </a>
                <button data-bs-toggle="collapse" className="navbar-toggler" data-bs-target="#navcol-1">
                    <span className="visually-hidden">Toggle navigation</span><span className="navbar-toggler-icon"></span></button>
                <div className="collapse navbar-collapse" id="navcol-1">
                    <ul className="navbar-nav ms-auto">
                        <li className="nav-item">
                            <Link className="nav-link" to="/">Home</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/service">Services</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/aboutUs">About Us</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/careers">Careers</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/contactUs">Contacts</Link>
                        </li>
                        {/* <li className="nav-item">
                            <Link className="nav-link" to="/faq">FAQ</Link>
                        </li> */}
                    </ul>
                </div>
            </div>
        </nav>
    )
}
export default Header;