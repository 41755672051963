import React from "react";

function ChatIcon() {
    const contactNumber = process.env.REACT_APP_CONTACT_NO; 
    const whatsappLink = `https://wa.me/${contactNumber}?text=Hi, i am interested in your IT solution business`;
    return (
        <a href={whatsappLink} target="_blank" rel="noopener noreferrer">
        <img
            src="assets/img/WhatsApp.svg.webp"
            alt="WhatsApp Icon"
            style={{
            position: 'fixed',
            bottom: '20px',
            right: '20px',
            width: '50px',
            height: '50px',
            borderRadius: '50%',
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.3)',
            zIndex: '9999',
            }}
        />
        </a>
    )
}
export default ChatIcon;